import { lazy } from 'react';

const About = lazy(() => import("./about"));
const Contact = lazy(() => import("./contact"));
const Home = lazy(() => import("./home"));
const Login = lazy(() => import("./login"));
const Register = lazy(() => import("./register"));
const ManualInput = lazy(() => import("./manual-input"));
const Reports = lazy(() => import("./reports"));
const TermCondition = lazy(() => import("./term-condition"));
const NotFound = lazy(() => import("./not-found"));
const EditReport = lazy(() => import("./edit-report"));
const Compare=lazy(()=> import("./compare-page"));
const CompareReport=lazy(()=> import("./compare-report-page"));
const LoadTemplates=lazy(()=> import("./load-template-page"));


export { About, Contact, Home, Login, Register, Reports, ManualInput, TermCondition, NotFound, EditReport,Compare,CompareReport};