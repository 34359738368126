import {StorageHelper, StorageKeys} from 'utils';

export class UserHelper {
  public static isLoggedIn() {
    const token = UserHelper.getToken();
    return !!token;
  }

  public static getToken() {
    return StorageHelper.get(StorageKeys.Token);
  }

  public static getUserData() {
    return StorageHelper.get(StorageKeys.User);
  }

  public static getSizingReportId() {
    return StorageHelper.get(StorageKeys.SizingReportId);
  }


  public static storeSizingReportId(sizingReportId: string) {
    StorageHelper.set(StorageKeys.SizingReportId, sizingReportId);
  }
  public static storeContainerId(containerId: string) {
    StorageHelper.set(StorageKeys.containerId, containerId);
  }


  public static storeUserData(
    token: string,
    user: any,
    sizingReportIdExist: boolean
  ) {
    StorageHelper.set(StorageKeys.Token, token);
    StorageHelper.set(StorageKeys.User, user);

    if (sizingReportIdExist) StorageHelper.remove(StorageKeys.SizingReportId);
  }

  // public static logoutUser() {
  //   StorageHelper.clear();
  // }
  public static logoutUser() {
    const language = StorageHelper.get('language');
    StorageHelper.clear(); 
    if (language) {
      StorageHelper.set('language', language);
    }
  }

  public static getRegions() {
    return StorageHelper.get(StorageKeys.Regions);
  }
}
