import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import { StorageHelper, StorageKeys } from "utils";
import SessionModal from "./SessionModal";

const SessionExpiry = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [showWarning, setShowWarning] = useState(10);
  const [showModal, setShowModal] = useState(false);


  
  useEffect(() => {
    const interval = setInterval(() => {

      const expirationTime = parseInt(StorageHelper.get(StorageKeys.expireToken)) ;
      // console.log(`Expiration Time from Storage (ms): ${expirationTime}`);
      

      if (expirationTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeRemaining = expirationTime - currentTime;
        const minutesLeft = Math.floor(timeRemaining / 60);

        if (minutesLeft <= 1 && minutesLeft > 0) {
       
          setShowWarning(minutesLeft );
          setShowModal(true);
       
        } else if (minutesLeft <= 0) {
      
          StorageHelper.remove(StorageKeys.Token);
          navigate('/login');
        } else {
          setShowWarning(10);
        }
      }
    }, 1000 * 20); 

    return () => clearInterval(interval);
  }, [navigate]);



  return <SessionModal show={showModal} />;
 
};

export default SessionExpiry;
